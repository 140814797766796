import { Col, Row, Container } from "react-bootstrap";

import profile from "../../assets/img/profile.jpg";

const Step3 = ({ trainerData }) => {
  return (
    <Container className="mx-auto recomendations pl-0">
      <Row>
        <Col md={12}>
          <h1>Recomendations</h1>
          <h2>Here’s what other people have to say about me</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="pl-0">
          <div className="boxRecomendations">
            {trainerData?.recommendations?.length ? (
              <Row>
                {trainerData.recommendations.map((recommendation) => (
                  <Col className="text-center">
                    <img
                      src={
                        recommendation.social_media
                          ? recommendation.social_media
                          : recommendation.trainer_from.category.image
                      }
                      alt="profile img"
                      className="profileImg"
                    />
                    <h5 className="profileName">
                      {recommendation.trainer_from.name}
                    </h5>
                    <p className="profileActivity"> {recommendation.text}</p>
                  </Col>
                ))}
                {/* <Col className="text-center">
                  <img src={profile} alt="profile img" className="profileImg" />
                  <h5 className="profileName">John Smith</h5>
                  <p className="profileActivity">Studio Onwer</p>
                </Col>
                <Col className="text-center">
                  <img src={profile} alt="profile img" className="profileImg" />
                  <h5 className="profileName">John Smith</h5>
                  <p className="profileActivity">Studio Onwer</p>
                </Col>
                <Col className="text-center">
                  <img src={profile} alt="profile img" className="profileImg" />
                  <h5 className="profileName">John Smith</h5>
                  <p className="profileActivity">Studio Onwer</p>
                </Col>
                <Col className="text-center">
                  <img src={profile} alt="profile img" className="profileImg" />
                  <h5 className="profileName">John Smith</h5>
                  <p className="profileActivity">Studio Onwer</p>
                </Col>
                <Col className="text-center">
                  <img src={profile} alt="profile img" className="profileImg" />
                  <h5 className="profileName">John Smith</h5>
                  <p className="profileActivity">Studio Onwer</p>
                </Col> */}
              </Row>
            ) : (
              <div className="boxRecomendations--null">
                <p>No recommendations yet</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Step3;
