import UpdateItem from "../../Components/UpdateItem/UpdateItem";
import { Container, Row, Col } from "react-bootstrap";
const Step2 = ({ trainerData }) => {
  const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  return (
    <Container>
      <Row>
        <Col md={12} className="mt-4 pt-5">
          <h2 className="titleStep2">What I've been up to</h2>
          <h4 className="subtitleStep2">Check out my latest updates</h4>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {trainerData.highlights.length ? (
            <div className="step2__container">
              <div className="step2">
                <div className="step2__item--container">
                  {trainerData.highlights.map((highlight) => (
                    <UpdateItem
                      key={highlight.id}
                      text={highlight.tag && capitalize(highlight.tag)}
                      title={highlight.title && capitalize(highlight.title)}
                      image={highlight.media}
                    />
                  ))}{" "}
                </div>
              </div>
            </div>
          ) : (
            <Row>
              <Col md={12} className="mb-5 mt-5">
                <div className="field--null">
                  <p>No highlights yet</p>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Step2;
