import ProfilePicture from "../../assets/img/profile-picture-default.png";
import LocationLogo from "../../assets/img/location_logo.svg";
//import FollowIcon from "../../assets/img/follow_icon.svg";
//import FollowingIcon from "../../assets/img/following_icon.svg";
import MuteIcon from "../../assets/img/mute.png";
import UnmuteIcon from "../../assets/img/unmute.png";
//import MessageIcon from "../../assets/img/message_icon.svg";
import DumbellIcon from "../../assets/img/dumbell_image.png";
import { useState } from "react";

const Step1 = ({ trainerData }) => {
  const getLocation = () => {
    return `${trainerData.address.city || "Some City"}, ${
      trainerData.address.country || "Some Country"
    }`;
  };
  //const [following, setFollowing] = useState(false);
  const [mute, setMute] = useState(true);

  let pictureMedia = trainerData.media ? trainerData.media : ProfilePicture;
  let fileExtension = pictureMedia.split(".").pop();

  const handleMute = () => {
    if (mute === true) {
      setMute(false);
    } else {
      setMute(true);
    }
  };

  //FOR YEARSWORKING
  const yearsWorking = [
    {
      label: "< 1",
      value: "0.9",
    },
    {
      label: "1-2",
      value: "1.5",
    },
    {
      label: "3-4",
      value: "3.5",
    },
    {
      label: "5-9",
      value: "5.5",
    },
    {
      label: "10+",
      value: "10.1",
    },
  ];

  function findByValue(arrayOfObjects, key, value) {
    try {
      return arrayOfObjects.find((obj) => {
        if (obj[key] !== undefined) {
          return obj[key] === value;
        }
        return false;
      });
    } catch (e) {}
    return false;
  }

  function translateYearsWorking(ywk) {
    try {
      let dat = findByValue(yearsWorking, "value", ywk.toString());
      if (dat) {
        return dat.label;
      }
      return yearsWorking[0].label;
    } catch (e) {
      if (yearsWorking[0]) {
        return yearsWorking[0].label;
      }
    }
    return "";
  }

  return (
    <div className="step1__container">
      <div className="step1">
        <div className="step1__left">
          {fileExtension === "jpg" ||
          fileExtension === "png" ||
          fileExtension === "gif" ? (
            <img src={pictureMedia} alt="Profile pic" />
          ) : (
            <video autoPlay muted={mute} loop>
              <source src={pictureMedia} type={`video/mp4`} />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <div className="step1__right">
          <div className="step1__info">
            <h3>Hi I'm {trainerData.name}!</h3>
            <p>
              <span>{trainerData.role || "trainer"}</span> with{" "}
              {trainerData.years_working
                ? `over ${translateYearsWorking(
                    trainerData.years_working
                  )} years of experience`
                : " several years of experience"}
            </p>
            <div className="step1__info--location">
              <img src={LocationLogo} alt="Location Logo" />
              <p>
                {trainerData.address ? getLocation() : "No location set yet"}
              </p>
            </div>
          </div>
          <div className="step1__social-and-skills">
            <div className="step1__social--container">
              <div className="step1__social">
                {fileExtension !== "jpg" ||
                  fileExtension !== "png" ||
                  (fileExtension !== "gif" && (
                    <button
                      className="step1__social--message"
                      style={{ width: "50px", marginRight: "10px" }}
                      onClick={() => handleMute()}
                    >
                      {mute ? (
                        <img
                          src={UnmuteIcon}
                          alt="Unmute Icon"
                          style={{ width: "20px" }}
                        />
                      ) : (
                        <img
                          src={MuteIcon}
                          alt="Mute Icon"
                          style={{ width: "20px" }}
                        />
                      )}
                    </button>
                  ))}
              </div>

              {/* {!following && (
                <div className="step1__social">
                  <button
                    onClick={() => setFollowing(true)}
                    className="step1__social--follow"
                  >
                    <img src={FollowIcon} alt="Follow Icon" />
                    <p>Follow</p>
                  </button>          
                </div>
              )}
              {following && (
                <div className="step1__social">
                  <button
                    onClick={() => setFollowing(false)}
                    className="step1__social--message"
                    style={{ width: "50px" }}
                  >
                    <img src={FollowingIcon} alt="Message Icon" />
                  </button>
                  <button className="step1__social--follow">
                    <img src={MessageIcon} alt="Follow Icon" />
                    <p>Message</p>
                  </button>
                </div>
              )} */}
            </div>
            <div className="step1__skills__container">
              <div className="step1__skills">
                <div className="step1__skills--main step1--card">
                  {trainerData.category ? (
                    <img
                      src={trainerData.category.image}
                      alt={trainerData.category.name}
                    />
                  ) : (
                    <img src={DumbellIcon} alt="" />
                  )}
                  <p>
                    <strong>
                      {trainerData.category
                        ? trainerData.category.name
                        : "No category selected"}
                    </strong>
                  </p>
                </div>
                {trainerData.specialties && (
                  <>
                    {trainerData.specialties.map((spec, key) => (
                      <p key={key} className="step1--card">
                        {spec.name}
                      </p>
                    ))}
                  </>
                )}
                {!trainerData.specialties && (
                  <p className="step1--card">No specialties</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
