import { Col, Row, Container, Tab, Tabs } from "react-bootstrap";
import FittlyText from "../../assets/img/fittly-text.png";

const Step5 = ({ trainerData }) => {
  return (
    <Container className="mx-auto myExperience pt-5 pl-0">
      <Row>
        <Col md={12}>
          <h1>My experience</h1>
          <p>Here are a few of my most relevant roles and education</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {trainerData?.experiences?.length ? (
            <div className="boxHistory">
              <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                {trainerData?.experiences && (
                  <Tab eventKey="home" title="Work Experience">
                    <Row>
                      <Col md={12}>
                        <ul className="timeline">
                          {trainerData.roles.map((role) => (
                            <li
                              className="timeline-item bg-white rounded ml-3 p-4 shadow"
                              key={`${role.id}_role`}
                            >
                              <div className="timeline-arrow" />
                              <h2 className="h5 mb-0">{role.role_title}</h2>
                              <span className="small text-gray">
                                <i className="fa fa-clock-o mr-1" />
                                {role.company_name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Tab>
                )}
                {trainerData.experiences && (
                  <Tab eventKey="profile" title="Education & Certification ">
                    <Row>
                      <Col md={12}>
                        <ul className="timeline">
                          {trainerData.experiences.map((experience) => (
                            <li
                              className="timeline-item bg-white rounded ml-3 p-4 shadow"
                              key={`${experience.id}_exp`}
                            >
                              <div className="timeline-arrow" />
                              <h2 className="h5 mb-0">
                                {experience.course_name}
                              </h2>
                              <span className="small text-gray">
                                <i className="fa fa-clock-o mr-1" />
                                {experience.institution}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Tab>
                )}
              </Tabs>
            </div>
          ) : (
            <Col md={12}>
              <div className="field--null">
                <p>No roles yet</p>
              </div>
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <div className="spacer--fittly">
          <img src={FittlyText} alt="Fittly" />
        </div>
      </Row>
    </Container>
  );
};

export default Step5;
