/* eslint-disable no-useless-escape */
import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Step1 from "./Pages/Landing/Step1";
import Step2 from "./Pages/Landing/Step2";
import Step3 from "./Pages/Landing/Step3";
import Step4 from "./Pages/Landing/Step4";
import Step5 from "./Pages/Landing/Step5";

import loadingSpin from "./assets/img/loading.svg";


function getQueryStringValue(key) {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
        encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
        "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
}


function App() {

  const [trainerData, setTrainerData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  const handleFittlyRedirection = (job) => {
    const jobTarget = parseInt(job);
    window.location.replace(`com.fittly.app://jobs/${jobTarget}`);
    setTimeout(() => window.location.replace(`https://portal.fittly.com/job/${jobTarget}`), 3000);
  }

  useEffect(() => {
    setLoadingData(true);
    let userTarget = getQueryStringValue("u");
    let jobTarget = getQueryStringValue("j");
    if(jobTarget && parseInt(jobTarget) > 0){
      handleFittlyRedirection(jobTarget);
    }else if(userTarget){
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/user/profile/${parseInt(userTarget)}`,
        withCredentials: false,
      }).then((res) => {
        setTrainerData(res.data);
        setLoadingData(false);
      })
        .catch(() => {})
        .finally(() => {
          setLoadingData(false);
        });
    }else{
      setTimeout(function(){
        window.location.replace(`https://fittly.com`);
      }, 3000);
    }
  }, []);

  return (
    <div className="appContainer">
      <div className="big-f-circle" />
      <div className="app">
        {loadingData ? (
          <Container fluid className="app-container">
            <div className="loading text-center">
              <img src={loadingSpin} alt="Loading..." />
              <h1>Loading...</h1>
            </div>
          </Container>
        ) : trainerData === null ? (
          <Container fluid className="app-container">
            <div className="loading">
              <h1>No trainer data found</h1>
            </div>
          </Container>
        ) : (
          <Container fluid className="m-0 app-container">
            <Container>
              <Step1 trainerData={trainerData}/>
              <Step2 trainerData={trainerData}/>
              <Step3 trainerData={trainerData}/>
              <Step4 trainerData={trainerData}/>
              <Step5 trainerData={trainerData}/>
            </Container>
          </Container>
        )}
      </div>
    </div>
  );
}

export default App;
